// 安徽含山地理数据
const anhuihanshan_mapData = [
    [117.968371, 31.465654],
    [117.969335, 31.45571],
    [117.976059, 31.448209],
    [117.976214, 31.445235],
    [117.974338, 31.44211],
    [117.974651, 31.437576],
    [117.979654, 31.432884],
    [117.982938, 31.432259],
    [117.986691, 31.434292],
    [117.988409, 31.438043],
    [117.991224, 31.438511],
    [118.116137, 31.410287],
    [118.165149, 31.399137],
    [118.16644, 31.399792],
    [118.168521, 31.401852],
    [118.168605, 31.403403],
    [118.164098, 31.40837],
    [118.163024, 31.410862],
    [118.162694, 31.413765],
    [118.163535, 31.416098],
    [118.167505, 31.423556],
    [118.169695, 31.426662],
    [118.171213, 31.429509],
    [118.169945, 31.430617],
    [118.166832, 31.430815],
    [118.165881, 31.432711],
    [118.164114, 31.433377],
    [118.160642, 31.433164],
    [118.156947, 31.43386],
    [118.14924, 31.436956],
    [118.148108, 31.437017],
    [118.144468, 31.436163],
    [118.142898, 31.436193],
    [118.138828, 31.437205],
    [118.137285, 31.437322],
    [118.134441, 31.436798],
    [118.131655, 31.437245],
    [118.129752, 31.439152],
    [118.128785, 31.442003],
    [118.128691, 31.443314],
    [118.129629, 31.448869],
    [118.129325, 31.449977],
    [118.127833, 31.452386],
    [118.127419, 31.453657],
    [118.127465, 31.456325],
    [118.128733, 31.458591],
    [118.127711, 31.461356],
    [118.129257, 31.462875],
    [118.138239, 31.468657],
    [118.141093, 31.471579],
    [118.142513, 31.47166],
    [118.142779, 31.469028],
    [118.145768, 31.467316],
    [118.146221, 31.467484],
    [118.145933, 31.46975],
    [118.146839, 31.469862],
    [118.150098, 31.468307],
    [118.150813, 31.468475],
    [118.151233, 31.470451],
    [118.15, 31.471376],
    [118.149755, 31.472885],
    [118.150826, 31.473368],
    [118.151175, 31.474526],
    [118.146069, 31.476482],
    [118.144921, 31.477346],
    [118.144073, 31.480633],
    [118.142238, 31.48394],
    [118.141908, 31.485764],
    [118.141895, 31.491032],
    [118.141536, 31.492551],
    [118.140378, 31.494431],
    [118.136641, 31.499236],
    [118.136146, 31.500531],
    [118.13467, 31.507348],
    [118.134146, 31.514179],
    [118.135084, 31.517023],
    [118.139022, 31.52011],
    [118.138165, 31.522167],
    [118.138453, 31.536182],
    [118.140653, 31.53866],
    [118.143393, 31.541356],
    [118.146399, 31.543356],
    [118.147836, 31.547225],
    [118.150347, 31.551302],
    [118.15112, 31.560683],
    [118.150696, 31.565048],
    [118.150541, 31.569403],
    [118.149845, 31.573545],
    [118.149657, 31.576758],
    [118.150308, 31.581102],
    [118.151198, 31.584746],
    [118.151858, 31.589547],
    [118.152256, 31.594352],
    [118.152362, 31.598711],
    [118.151939, 31.603069],
    [118.153168, 31.609234],
    [118.154042, 31.611969],
    [118.157611, 31.61556],
    [118.160597, 31.617103],
    [118.163842, 31.618412],
    [118.16954, 31.621273],
    [118.173057, 31.622805],
    [118.175791, 31.625042],
    [118.178512, 31.626589],
    [118.179117, 31.629556],
    [118.178952, 31.633457],
    [118.177713, 31.637378],
    [118.175455, 31.643154],
    [118.174766, 31.647293],
    [118.176202, 31.651385],
    [118.179231, 31.654535],
    [118.182774, 31.656751],
    [118.186323, 31.6592],
    [118.190151, 31.662328],
    [118.193762, 31.667298],
    [118.197068, 31.670664],
    [118.198809, 31.675902],
    [118.199443, 31.679785],
    [118.199278, 31.683846],
    [118.198408, 31.685965],
    [118.197285, 31.687293],
    [118.1957, 31.690051],
    [118.195373, 31.691201],
    [118.196127, 31.693837],
    [118.202126, 31.699448],
    [118.203808, 31.700584],
    [118.205953, 31.701308],
    [118.210111, 31.70219],
    [118.214411, 31.704339],
    [118.218575, 31.706828],
    [118.224238, 31.711308],
    [118.22492, 31.713188],
    [118.221746, 31.718251],
    [118.218022, 31.722193],
    [118.216971, 31.722198],
    [118.210638, 31.720212],
    [118.204313, 31.719127],
    [118.200353, 31.718028],
    [118.189808, 31.714491],
    [118.183207, 31.71183],
    [118.180577, 31.710953],
    [118.176351, 31.709169],
    [118.17241, 31.710092],
    [118.168469, 31.712372],
    [118.165929, 31.713391],
    [118.164616, 31.719989],
    [118.164978, 31.726855],
    [118.167786, 31.732773],
    [118.169352, 31.738392],
    [118.173517, 31.745115],
    [118.178169, 31.751078],
    [118.182123, 31.754644],
    [118.183045, 31.75627],
    [118.18222, 31.75897],
    [118.179441, 31.765443],
    [118.178309, 31.773486],
    [118.178163, 31.778069],
    [118.179625, 31.782855],
    [118.179502, 31.788582],
    [118.177729, 31.792517],
    [118.175115, 31.795089],
    [118.17142, 31.797231],
    [118.167401, 31.79754],
    [118.1596, 31.796557],
    [118.155549, 31.795489],
    [118.152068, 31.795565],
    [118.149185, 31.798137],
    [118.14791, 31.800917],
    [118.148544, 31.804572],
    [118.152434, 31.809761],
    [118.155465, 31.812905],
    [118.159031, 31.816044],
    [118.160173, 31.818539],
    [118.160254, 31.821744],
    [118.157863, 31.822711],
    [118.154912, 31.822771],
    [118.15033, 31.821946],
    [118.147072, 31.820407],
    [118.140326, 31.818712],
    [118.136845, 31.819238],
    [118.133674, 31.821136],
    [118.131593, 31.823693],
    [118.125753, 31.82633],
    [118.121453, 31.826178],
    [118.113979, 31.82387],
    [118.110426, 31.824128],
    [118.107482, 31.825687],
    [118.103343, 31.828461],
    [118.09651, 31.831244],
    [118.085612, 31.839145],
    [118.083623, 31.842708],
    [118.083506, 31.846022],
    [118.084195, 31.848598],
    [118.086712, 31.853223],
    [118.085781, 31.8542],
    [118.083972, 31.85382],
    [118.080804, 31.854286],
    [118.076834, 31.853168],
    [118.074175, 31.851376],
    [118.072854, 31.850926],
    [118.068972, 31.852186],
    [118.065979, 31.854595],
    [118.063995, 31.857069],
    [118.061779, 31.858541],
    [118.057812, 31.859493],
    [118.052833, 31.859639],
    [118.044821, 31.859533],
    [118.040851, 31.860889],
    [118.039528, 31.861572],
    [118.031588, 31.864274],
    [118.021535, 31.868792],
    [118.016242, 31.870816],
    [118.014918, 31.871494],
    [118.009622, 31.873295],
    [118.002474, 31.876007],
    [118.000882, 31.876457],
    [117.994262, 31.879381],
    [117.991616, 31.880286],
    [117.98838, 31.881814],
    [117.964343, 31.901551],
    [117.960586, 31.902294],
    [117.954814, 31.902962],
    [117.953565, 31.904039],
    [117.95185, 31.906244],
    [117.94943, 31.904545],
    [117.948139, 31.903002],
    [117.94833, 31.900767],
    [117.949411, 31.898749],
    [117.949627, 31.89579],
    [117.947625, 31.890777],
    [117.946712, 31.88667],
    [117.944713, 31.881657],
    [117.944062, 31.876629],
    [117.943166, 31.872976],
    [117.941723, 31.868873],
    [117.940584, 31.866374],
    [117.938888, 31.863419],
    [117.93775, 31.860464],
    [117.936323, 31.857504],
    [117.93574, 31.855455],
    [117.931327, 31.850936],
    [117.929424, 31.849823],
    [117.928033, 31.848244],
    [117.925047, 31.846913],
    [117.922588, 31.845354],
    [117.920138, 31.844246],
    [117.913874, 31.840911],
    [117.910833, 31.837753],
    [117.908031, 31.83253],
    [117.907432, 31.83002],
    [117.906296, 31.827064],
    [117.906235, 31.824543],
    [117.903672, 31.818626],
    [117.90309, 31.816808],
    [117.901695, 31.814535],
    [117.901365, 31.811791],
    [117.902847, 31.806273],
    [117.905407, 31.800501],
    [117.907212, 31.797489],
    [117.909034, 31.794937],
    [117.911396, 31.792152],
    [117.912677, 31.789838],
    [117.913434, 31.787534],
    [117.912027, 31.785265],
    [117.909823, 31.782323],
    [117.905944, 31.777578],
    [117.905041, 31.773471],
    [117.905785, 31.770939],
    [117.909464, 31.767439],
    [117.914085, 31.765317],
    [117.918291, 31.764567],
    [117.921397, 31.762602],
    [117.924739, 31.761406],
    [117.925121, 31.760302],
    [117.924778, 31.759046],
    [117.923694, 31.758023],
    [117.91831, 31.754892],
    [117.915955, 31.752608],
    [117.915275, 31.750323],
    [117.915253, 31.746088],
    [117.915651, 31.744426],
    [117.917113, 31.742582],
    [117.918333, 31.74164],
    [117.920057, 31.734146],
    [117.919999, 31.728309],
    [117.918631, 31.723561],
    [117.914583, 31.716766],
    [117.913075, 31.715362],
    [117.913221, 31.70405],
    [117.915832, 31.698708],
    [117.922584, 31.693285],
    [117.923251, 31.692387],
    [117.926312, 31.686943],
    [117.927985, 31.685326],
    [117.932259, 31.681818],
    [117.934139, 31.680845],
    [117.940302, 31.679562],
    [117.941441, 31.67905],
    [117.942173, 31.67727],
    [117.941555, 31.673838],
    [117.939193, 31.671379],
    [117.942247, 31.671496],
    [117.944133, 31.670624],
    [117.949284, 31.671349],
    [117.953746, 31.668281],
    [117.955121, 31.666238],
    [117.967653, 31.662146],
    [117.963877, 31.659372],
    [117.964734, 31.656446],
    [117.967653, 31.65528],
    [117.965249, 31.653378],
    [117.969025, 31.642125],
    [117.973318, 31.638473],
    [117.971943, 31.620497],
    [117.960787, 31.616697],
    [117.959062, 31.622455],
    [117.962709, 31.627167],
    [117.947223, 31.627806],
    [117.945887, 31.628445],
    [117.941121, 31.626908],
    [117.940306, 31.625153],
    [117.938804, 31.622962],
    [117.938374, 31.621065],
    [117.937216, 31.62037],
    [117.936229, 31.619056],
    [117.934614, 31.618122],
    [117.934504, 31.616423],
    [117.935591, 31.614094],
    [117.935747, 31.612943],
    [117.937125, 31.611482],
    [117.93719, 31.610462],
    [117.935326, 31.609061],
    [117.932815, 31.60795],
    [117.931838, 31.605682],
    [117.932126, 31.604465],
    [117.935381, 31.598604],
    [117.935291, 31.594966],
    [117.935646, 31.591597],
    [117.93618, 31.590592],
    [117.936031, 31.588933],
    [117.934702, 31.586893],
    [117.931971, 31.586913],
    [117.930573, 31.587598],
    [117.929596, 31.587131],
    [117.929263, 31.584046],
    [117.929551, 31.582848],
    [117.930816, 31.581143],
    [117.932391, 31.58061],
    [117.934546, 31.581122],
    [117.935346, 31.580503],
    [117.93541, 31.578509],
    [117.934543, 31.576753],
    [117.933569, 31.575778],
    [117.930188, 31.573555],
    [117.929635, 31.572525],
    [117.929437, 31.570043],
    [117.928253, 31.568226],
    [117.926438, 31.567789],
    [117.92415, 31.568028],
    [117.921112, 31.56684],
    [117.919061, 31.567312],
    [117.917207, 31.566759],
    [117.913916, 31.565013],
    [117.913706, 31.563749],
    [117.913968, 31.560592],
    [117.908658, 31.558333],
    [117.907448, 31.557597],
    [117.907238, 31.556698],
    [117.908603, 31.553652],
    [117.908658, 31.552601],
    [117.907397, 31.550444],
    [117.90771, 31.547134],
    [117.90829, 31.54687],
    [117.913722, 31.549571],
    [117.916806, 31.550134],
    [117.919802, 31.549235],
    [117.922381, 31.545981],
    [117.928053, 31.538416],
    [117.930175, 31.537583],
    [117.932605, 31.53766],
    [117.938769, 31.539848],
    [117.941655, 31.540655],
    [117.945217, 31.540777],
    [117.951038, 31.539884],
    [117.95196, 31.538629],
    [117.952148, 31.536969],
    [117.950873, 31.535202],
    [117.949252, 31.533765],
    [117.947693, 31.531536],
    [117.945026, 31.528723],
    [117.939552, 31.525087],
    [117.93971, 31.52398],
    [117.941817, 31.522091],
    [117.94611, 31.520644],
    [117.952407, 31.519836],
    [117.954633, 31.518531],
    [117.956351, 31.515712],
    [117.957144, 31.50978],
    [117.95825, 31.507403],
    [117.961596, 31.505925],
    [117.964333, 31.504986],
    [117.96576, 31.503833],
    [117.966038, 31.502522],
    [117.965611, 31.499007],
    [117.963977, 31.492942],
    [117.963628, 31.489508],
    [117.963016, 31.486541],
    [117.964844, 31.483991],
    [117.969656, 31.483463],
    [117.97447, 31.482243],
    [117.978456, 31.480348],
    [117.981902, 31.478464],
    [117.986908, 31.474496],
    [117.988464, 31.471955],
    [117.987073, 31.470141],
    [117.982727, 31.468368],
    [117.980275, 31.466803],
    [117.975434, 31.466412],
    [117.973283, 31.466442],
    [117.968371, 31.465654]
]

// 河北馆陶地理数据
const hebeiguantao_mapData = [
    [115.332137, 36.777094],
    [115.332579, 36.770607],
    [115.325754, 36.763012],
    [115.320352, 36.75909],
    [115.315816, 36.752648],
    [115.317017, 36.741658],
    [115.321628, 36.731831],
    [115.3251, 36.722456],
    [115.324557, 36.716038],
    [115.319755, 36.712263],
    [115.318634, 36.712323],
    [115.316869, 36.70756],
    [115.314242, 36.706699],
    [115.310285, 36.707393],
    [115.307654, 36.707022],
    [115.306547, 36.706315],
    [115.30594, 36.704235],
    [115.297173, 36.706759],
    [115.296473, 36.703324],
    [115.293856, 36.703714],
    [115.292914, 36.701711],
    [115.297418, 36.700757],
    [115.298306, 36.699929],
    [115.2963, 36.69229],
    [115.296282, 36.690554],
    [115.298575, 36.687342],
    [115.301623, 36.684707],
    [115.302593, 36.684413],
    [115.308286, 36.683195],
    [115.3138, 36.677464],
    [115.313947, 36.675073],
    [115.312585, 36.671022],
    [115.311273, 36.668888],
    [115.309886, 36.667793],
    [115.30765, 36.666778],
    [115.305706, 36.666691],
    [115.295829, 36.669523],
    [115.293389, 36.670572],
    [115.294467, 36.673527],
    [115.2883, 36.675013],
    [115.289543, 36.67693],
    [115.286585, 36.678025],
    [115.287254, 36.680072],
    [115.286668, 36.68084],
    [115.283742, 36.682296],
    [115.280605, 36.683195],
    [115.281589, 36.685485],
    [115.279648, 36.68608],
    [115.278761, 36.683829],
    [115.276604, 36.684233],
    [115.277337, 36.686584],
    [115.276493, 36.686971],
    [115.275059, 36.687048],
    [115.274433, 36.684784],
    [115.271608, 36.685372],
    [115.270498, 36.684904],
    [115.269355, 36.683455],
    [115.268151, 36.68076],
    [115.265725, 36.681832],
    [115.255765, 36.682023],
    [115.248663, 36.678326],
    [115.242155, 36.674402],
    [115.237331, 36.671867],
    [115.233956, 36.665672],
    [115.234848, 36.659486],
    [115.235487, 36.647578],
    [115.234959, 36.641156],
    [115.228748, 36.634033],
    [115.220051, 36.632336],
    [115.201013, 36.637164],
    [115.200452, 36.628934],
    [115.205304, 36.625482],
    [115.206116, 36.620907],
    [115.218056, 36.617271],
    [115.218947, 36.611092],
    [115.212172, 36.606028],
    [115.203137, 36.601823],
    [115.202421, 36.599664],
    [115.200373, 36.595993],
    [115.198885, 36.589016],
    [115.194716, 36.586719],
    [115.193504, 36.584449],
    [115.192246, 36.579785],
    [115.183286, 36.580206],
    [115.182532, 36.577474],
    [115.18214, 36.577408],
    [115.183811, 36.576378],
    [115.18348, 36.574147],
    [115.181216, 36.571104],
    [115.178301, 36.569245],
    [115.177485, 36.567887],
    [115.176425, 36.564189],
    [115.176184, 36.557577],
    [115.175217, 36.548552],
    [115.173855, 36.541223],
    [115.173579, 36.540892],
    [115.172112, 36.541257],
    [115.171774, 36.540979],
    [115.171814, 36.54024],
    [115.174682, 36.539734],
    [115.174542, 36.538714],
    [115.168353, 36.538781],
    [115.16607, 36.538978],
    [115.165197, 36.536255],
    [115.161067, 36.536874],
    [115.160604, 36.531056],
    [115.154788, 36.530668],
    [115.143463, 36.530347],
    [115.134579, 36.526191],
    [115.125601, 36.517672],
    [115.121306, 36.506079],
    [115.119127, 36.49327],
    [115.125087, 36.488001],
    [115.130801, 36.483207],
    [115.145009, 36.47777],
    [115.153239, 36.475279],
    [115.159763, 36.473009],
    [115.170028, 36.463647],
    [115.179955, 36.460248],
    [115.190712, 36.460505],
    [115.197781, 36.461212],
    [115.204869, 36.46077],
    [115.211669, 36.45942],
    [115.219339, 36.455773],
    [115.228148, 36.452598],
    [115.239761, 36.453101],
    [115.252222, 36.455663],
    [115.261574, 36.458453],
    [115.269488, 36.463067],
    [115.284763, 36.47551],
    [115.286366, 36.476387],
    [115.28468, 36.476424],
    [115.283491, 36.477017],
    [115.282822, 36.478416],
    [115.282754, 36.479327],
    [115.283174, 36.480274],
    [115.283789, 36.482812],
    [115.283789, 36.483863],
    [115.283519, 36.486401],
    [115.281629, 36.486756],
    [115.279289, 36.486766],
    [115.276069, 36.486953],
    [115.275648, 36.487452],
    [115.275806, 36.488647],
    [115.273402, 36.488938],
    [115.273272, 36.488101],
    [115.272644, 36.488121],
    [115.273118, 36.490826],
    [115.271785, 36.491017],
    [115.271447, 36.491412],
    [115.271986, 36.492674],
    [115.27273, 36.496125],
    [115.272848, 36.497384],
    [115.28169, 36.495757],
    [115.282905, 36.498475],
    [115.28335, 36.498451],
    [115.283667, 36.499231],
    [115.284037, 36.499208],
    [115.284314, 36.500105],
    [115.285237, 36.500001],
    [115.286093, 36.498167],
    [115.289744, 36.497805],
    [115.292695, 36.500754],
    [115.293781, 36.501912],
    [115.294765, 36.503807],
    [115.295937, 36.506417],
    [115.296505, 36.50884],
    [115.295441, 36.510698],
    [115.293957, 36.512545],
    [115.293446, 36.513402],
    [115.29262, 36.514386],
    [115.291883, 36.516434],
    [115.291869, 36.517876],
    [115.292174, 36.51896],
    [115.293108, 36.52067],
    [115.295186, 36.52321],
    [115.294553, 36.52339],
    [115.288835, 36.524311],
    [115.289245, 36.525187],
    [115.289482, 36.526235],
    [115.289302, 36.526546],
    [115.28821, 36.527155],
    [115.28803, 36.527466],
    [115.288332, 36.528513],
    [115.290416, 36.530206],
    [115.292012, 36.531739],
    [115.293597, 36.532742],
    [115.2957, 36.533385],
    [115.297127, 36.533452],
    [115.298302, 36.533251],
    [115.29956, 36.532762],
    [115.300383, 36.531742],
    [115.300462, 36.530159],
    [115.299833, 36.52779],
    [115.300527, 36.527627],
    [115.300476, 36.525957],
    [115.302967, 36.52669],
    [115.306019, 36.527329],
    [115.307467, 36.527432],
    [115.309235, 36.529273],
    [115.310112, 36.530497],
    [115.310637, 36.531725],
    [115.311341, 36.532742],
    [115.312165, 36.533636],
    [115.314515, 36.535462],
    [115.318727, 36.538062],
    [115.322796, 36.540765],
    [115.323745, 36.541608],
    [115.324665, 36.542668],
    [115.325686, 36.543561],
    [115.327659, 36.545729],
    [115.329273, 36.547204],
    [115.330254, 36.548362],
    [115.331275, 36.550221],
    [115.331361, 36.552867],
    [115.331113, 36.554513],
    [115.331116, 36.556824],
    [115.331641, 36.562008],
    [115.331939, 36.564199],
    [115.333241, 36.567667],
    [115.334941, 36.57382],
    [115.335117, 36.575742],
    [115.334976, 36.578337],
    [115.334452, 36.581206],
    [115.334294, 36.582487],
    [115.334994, 36.584168],
    [115.336612, 36.587254],
    [115.337834, 36.588983],
    [115.343114, 36.587304],
    [115.345407, 36.591159],
    [115.350525, 36.590073],
    [115.351556, 36.592794],
    [115.351556, 36.594984],
    [115.344943, 36.595635],
    [115.340371, 36.595231],
    [115.340281, 36.599396],
    [115.341173, 36.603023],
    [115.341489, 36.603478],
    [115.344648, 36.605202],
    [115.34798, 36.606015],
    [115.349561, 36.606122],
    [115.350744, 36.606666],
    [115.348918, 36.608368],
    [115.345378, 36.61052],
    [115.344677, 36.611383],
    [115.345044, 36.612385],
    [115.349342, 36.615527],
    [115.352394, 36.618665],
    [115.353472, 36.620239],
    [115.354015, 36.621379],
    [115.354564, 36.623096],
    [115.354931, 36.62467],
    [115.355132, 36.627394],
    [115.355848, 36.627394],
    [115.359208, 36.624948],
    [115.360621, 36.62437],
    [115.361321, 36.623938],
    [115.364901, 36.622635],
    [115.366044, 36.62196],
    [115.36814, 36.623447],
    [115.370318, 36.626014],
    [115.372532, 36.627965],
    [115.374383, 36.629786],
    [115.378494, 36.632857],
    [115.379209, 36.633672],
    [115.38036, 36.635587],
    [115.382678, 36.640304],
    [115.383706, 36.641878],
    [115.387117, 36.645343],
    [115.387961, 36.646432],
    [115.388198, 36.647223],
    [115.388371, 36.649088],
    [115.388234, 36.651259],
    [115.3873, 36.652799],
    [115.386186, 36.655124],
    [115.386308, 36.656296],
    [115.386768, 36.656804],
    [115.389223, 36.658551],
    [115.390581, 36.659296],
    [115.392069, 36.65992],
    [115.395933, 36.660929],
    [115.402118, 36.662432],
    [115.403883, 36.662626],
    [115.406424, 36.663231],
    [115.407758, 36.663795],
    [115.408458, 36.664476],
    [115.408854, 36.665345],
    [115.409709, 36.667953],
    [115.409709, 36.669339],
    [115.409343, 36.670772],
    [115.409264, 36.671634],
    [115.409605, 36.672495],
    [115.410935, 36.67495],
    [115.412132, 36.676489],
    [115.413282, 36.677297],
    [115.415604, 36.678653],
    [115.416905, 36.679177],
    [115.418612, 36.679685],
    [115.419421, 36.680126],
    [115.420204, 36.681355],
    [115.420308, 36.683078],
    [115.4201, 36.68464],
    [115.42015, 36.685739],
    [115.420581, 36.686771],
    [115.422095, 36.687081],
    [115.424675, 36.687419],
    [115.427644, 36.687729],
    [115.429574, 36.687859],
    [115.430796, 36.688223],
    [115.432151, 36.688924],
    [115.433675, 36.689979],
    [115.435184, 36.691492],
    [115.435968, 36.692066],
    [115.43714, 36.69238],
    [115.439145, 36.69235],
    [115.440532, 36.692447],
    [115.441859, 36.689849],
    [115.442768, 36.690106],
    [115.442908, 36.689792],
    [115.444932, 36.690407],
    [115.443976, 36.69244],
    [115.44463, 36.692704],
    [115.445072, 36.693198],
    [115.4461, 36.693916],
    [115.446689, 36.69463],
    [115.446031, 36.696179],
    [115.445072, 36.696887],
    [115.444145, 36.698513],
    [115.444213, 36.699622],
    [115.445126, 36.701378],
    [115.446154, 36.701775],
    [115.448652, 36.702015],
    [115.449565, 36.702008],
    [115.451423, 36.702212],
    [115.451976, 36.702599],
    [115.452199, 36.703441],
    [115.452084, 36.704352],
    [115.451743, 36.704879],
    [115.44992, 36.705801],
    [115.450014, 36.706362],
    [115.450078, 36.709192],
    [115.449881, 36.710831],
    [115.449917, 36.711966],
    [115.450506, 36.713625],
    [115.451858, 36.713401],
    [115.452655, 36.713565],
    [115.454431, 36.714189],
    [115.455182, 36.714049],
    [115.456365, 36.714176],
    [115.457892, 36.715297],
    [115.459779, 36.71741],
    [115.460045, 36.718515],
    [115.459793, 36.719119],
    [115.459315, 36.719493],
    [115.457195, 36.719927],
    [115.455822, 36.719993],
    [115.456534, 36.721709],
    [115.458435, 36.724916],
    [115.459351, 36.726061],
    [115.461547, 36.72568],
    [115.463754, 36.726011],
    [115.464879, 36.726401],
    [115.465389, 36.726792],
    [115.465846, 36.727666],
    [115.46526, 36.72861],
    [115.463165, 36.729692],
    [115.461828, 36.730479],
    [115.460961, 36.731384],
    [115.460908, 36.731877],
    [115.461767, 36.733483],
    [115.461896, 36.73413],
    [115.462582, 36.735208],
    [115.464232, 36.735338],
    [115.466935, 36.734454],
    [115.467992, 36.735832],
    [115.47128, 36.738902],
    [115.47275, 36.740764],
    [115.474544, 36.742899],
    [115.475482, 36.744227],
    [115.477365, 36.748124],
    [115.478407, 36.751167],
    [115.478515, 36.753165],
    [115.478343, 36.754689],
    [115.466579, 36.7558],
    [115.465857, 36.756337],
    [115.464272, 36.755447],
    [115.463406, 36.752144],
    [115.446215, 36.75532],
    [115.445787, 36.758282],
    [115.440939, 36.760748],
    [115.42837, 36.757935],
    [115.426871, 36.758329],
    [115.426328, 36.762122],
    [115.42364, 36.766055],
    [115.410396, 36.767862],
    [115.410097, 36.765581],
    [115.404778, 36.762726],
    [115.394826, 36.762782],
    [115.39066, 36.763119],
    [115.375917, 36.767642],
    [115.370857, 36.768343],
    [115.371119, 36.770097],
    [115.366709, 36.770798],
    [115.365757, 36.770751],
    [115.362899, 36.771054],
    [115.363237, 36.773412],
    [115.360128, 36.775567],
    [115.360358, 36.777525],
    [115.359453, 36.779316],
    [115.355729, 36.779522],
    [115.352753, 36.785805],
    [115.35212, 36.786295],
    [115.349784, 36.786786],
    [115.348832, 36.786452],
    [115.347786, 36.784431],
    [115.34761, 36.78222],
    [115.345105, 36.778922],
    [115.339103, 36.779462],
    [115.336522, 36.777385],
    [115.335667, 36.775807],
    [115.332137, 36.777094],
];

// 河南开封水稻乡地理数据
const shuidaoxiang_mapData = [
    [114.361765, 34.795279],
    [114.362158, 34.806823],
    [114.360889, 34.811973],
    [114.362454, 34.816148],
    [114.366889, 34.817198],
    [114.372516, 34.816182],
    [114.376283, 34.814937],
    [114.380171, 34.811582],
    [114.382636, 34.807097],
    [114.386742, 34.80505],
    [114.39715, 34.804467],
    [114.405507, 34.806818],
    [114.410943, 34.810188],
    [114.413549, 34.814622],
    [114.414589, 34.818535],
    [114.413028, 34.822709],
    [114.410164, 34.827145],
    [114.405997, 34.829231],
    [114.403912, 34.831578],
    [114.404174, 34.835231],
    [114.407562, 34.838361],
    [114.414072, 34.841752],
    [114.417091, 34.84367],
    [114.412837, 34.843645],
    [114.409436, 34.844509],
    [114.407774, 34.848924],
    [114.409308, 34.853846],
    [114.412649, 34.859512],
    [114.413213, 34.864436],
    [114.413777, 34.867983],
    [114.408089, 34.86965],
    [114.404406, 34.870283],
    [114.401072, 34.871439],
    [114.400944, 34.874072],
    [114.402126, 34.87716],
    [114.408791, 34.879446],
    [114.418068, 34.880585],
    [114.426764, 34.883641],
    [114.43405, 34.887878],
    [114.441097, 34.893289],
    [114.45026, 34.901991],
    [114.456125, 34.907637],
    [114.458241, 34.91046],
    [114.450495, 34.912597],
    [114.442746, 34.914496],
    [114.435463, 34.918275],
    [114.427942, 34.923939],
    [114.417836, 34.93078],
    [114.40725, 34.936439],
    [114.400662, 34.9395],
    [114.394307, 34.939968],
    [114.388417, 34.939493],
    [114.383002, 34.936896],
    [114.378761, 34.932413],
    [114.374282, 34.926515],
    [114.367211, 34.918966],
    [114.358253, 34.912117],
    [114.350474, 34.908097],
    [114.340099, 34.907135],
    [114.324772, 34.907576],
    [114.312507, 34.907385],
    [114.29993, 34.907945],
    [114.296163, 34.907765],
    [114.284468, 34.908223],
    [114.27175, 34.907739],
    [114.26069, 34.906556],
    [114.249164, 34.905144],
    [114.24399, 34.905146],
    [114.239759, 34.906796],
    [114.231533, 34.911516],
    [114.226601, 34.913171],
    [114.224486, 34.915766],
    [114.220726, 34.919068],
    [114.220961, 34.914826],
    [114.220491, 34.906815],
    [114.21814, 34.893149],
    [114.215555, 34.880192],
    [114.21344, 34.870533],
    [114.211093, 34.860875],
    [114.207336, 34.850512],
    [114.204986, 34.846036],
    [114.20287, 34.841327],
    [114.200993, 34.835674],
    [114.20053, 34.83379],
    [114.199808, 34.832706],
    [114.194886, 34.830261],
    [114.192046, 34.828207],
    [114.189067, 34.826437],
    [114.185337, 34.825636],
    [114.180905, 34.826049],
    [114.174842, 34.827026],
    [114.170245, 34.82755],
    [114.166444, 34.82835],
    [114.162489, 34.828869],
    [114.158682, 34.828709],
    [114.154881, 34.828363],
    [114.151211, 34.827965],
    [114.147263, 34.827681],
    [114.14498, 34.827113],
    [114.141246, 34.82648],
    [114.137365, 34.826143],
    [114.133702, 34.825629],
    [114.130099, 34.824649],
    [114.126782, 34.823279],
    [114.12373, 34.821103],
    [114.122491, 34.818353],
    [114.123807, 34.815381],
    [114.125271, 34.812575],
    [114.129431, 34.80725],
    [114.134152, 34.80215],
    [114.13991, 34.797228],
    [114.143304, 34.794707],
    [114.146491, 34.792475],
    [114.149546, 34.790126],
    [114.152803, 34.78818],
    [114.155771, 34.786115],
    [114.161734, 34.781237],
    [114.166166, 34.776255],
    [114.168795, 34.77384],
    [114.170873, 34.771263],
    [114.172334, 34.76829],
    [114.171504, 34.76537],
    [114.169365, 34.763023],
    [114.165904, 34.76211],
    [114.162241, 34.761317],
    [114.158373, 34.759375],
    [114.155402, 34.757146],
    [114.15285, 34.754627],
    [114.150711, 34.751937],
    [114.147535, 34.747475],
    [114.14588, 34.744843],
    [114.143324, 34.742323],
    [114.140353, 34.740432],
    [114.138553, 34.737798],
    [114.138493, 34.734765],
    [114.139114, 34.731509],
    [114.139886, 34.72853],
    [114.14044, 34.725375],
    [114.140726, 34.722053],
    [114.141219, 34.719078],
    [114.142881, 34.716392],
    [114.145305, 34.714153],
    [114.147028, 34.712832],
    [114.149798, 34.710891],
    [114.153877, 34.70787],
    [114.156869, 34.709538],
    [114.161284, 34.710835],
    [114.174795, 34.711073],
    [114.190642, 34.708173],
    [114.196874, 34.707901],
    [114.212987, 34.707875],
    [114.223126, 34.706298],
    [114.228847, 34.703945],
    [114.236912, 34.699764],
    [114.246018, 34.698455],
    [114.253831, 34.698715],
    [114.259817, 34.697411],
    [114.260851, 34.699998],
    [114.260851, 34.705084],
    [114.261241, 34.708409],
    [114.263195, 34.709976],
    [114.266126, 34.710367],
    [114.267687, 34.711736],
    [114.267493, 34.713888],
    [114.264954, 34.715649],
    [114.261439, 34.718189],
    [114.25968, 34.721516],
    [114.260072, 34.724253],
    [114.261439, 34.727383],
    [114.262806, 34.732667],
    [114.263786, 34.739123],
    [114.265542, 34.743622],
    [114.269453, 34.746754],
    [114.274141, 34.747931],
    [114.279617, 34.74774],
    [114.282548, 34.748526],
    [114.2847, 34.751461],
    [114.2847, 34.756156],
    [114.284116, 34.759482],
    [114.280987, 34.762608],
    [114.28001, 34.765739],
    [114.280792, 34.768477],
    [114.282746, 34.770435],
    [114.28607, 34.773374],
    [114.289112, 34.774707],
    [114.290176, 34.772955],
    [114.292191, 34.770613],
    [114.294249, 34.77046],
    [114.295484, 34.770188],
    [114.295911, 34.769469],
    [114.297818, 34.769081],
    [114.297912, 34.768552],
    [114.298452, 34.768292],
    [114.30043, 34.768246],
    [114.300437, 34.7693],
    [114.300675, 34.769348],
    [114.300816, 34.770642],
    [114.30229, 34.770615],
    [114.302196, 34.769982],
    [114.306641, 34.769678],
    [114.307152, 34.769717],
    [114.308102, 34.770324],
    [114.308827, 34.770503],
    [114.309636, 34.770316],
    [114.309656, 34.770042],
    [114.308827, 34.769598],
    [114.308488, 34.768508],
    [114.308817, 34.767649],
    [114.310194, 34.767528],
    [114.311164, 34.767904],
    [114.311691, 34.768601],
    [114.311936, 34.769317],
    [114.31334, 34.769431],
    [114.313501, 34.770287],
    [114.315619, 34.770282],
    [114.317144, 34.770142],
    [114.31753, 34.771035],
    [114.318819, 34.770989],
    [114.319279, 34.776988],
    [114.320028, 34.782062],
    [114.325323, 34.783606],
    [114.326498, 34.785064],
    [114.329808, 34.784683],
    [114.329798, 34.784875],
    [114.331527, 34.7871],
    [114.332864, 34.793062],
    [114.332938, 34.794013],
    [114.332128, 34.796015],
    [114.332159, 34.797732],
    [114.3323, 34.798722],
    [114.332696, 34.799863],
    [114.333196, 34.800605],
    [114.334915, 34.800413],
    [114.340042, 34.799967],
    [114.351226, 34.799773],
    [114.351085, 34.795597],
    [114.352542, 34.795367],
    [114.361765, 34.795279]
]

// 吉林白城地理数据
const jilinbaicheng_mapData = [
    [124.373785, 45.458551],
    [124.364397, 45.481896],
    [124.356268, 45.486796],
    [124.352494, 45.496646],
    [124.364784, 45.505968],
    [124.368849, 45.51292],
    [124.368946, 45.525766],
    [124.358688, 45.544821],
    [124.34872, 45.546926],
    [124.295396, 45.539189],
    [124.283686, 45.541295],
    [124.264621, 45.555082],
    [124.261233, 45.569234],
    [124.272847, 45.584014],
    [124.267621, 45.592113],
    [124.254169, 45.593795],
    [124.245943, 45.591114],
    [124.234523, 45.593427],
    [124.227555, 45.598895],
    [124.225136, 45.608989],
    [124.236652, 45.623023],
    [124.226587, 45.633428],
    [124.212458, 45.63059],
    [124.18749, 45.615928],
    [124.167747, 45.615297],
    [124.14723, 45.623075],
    [124.129714, 45.637578],
    [124.130198, 45.64362],
    [124.143069, 45.648348],
    [124.149359, 45.661584],
    [124.144714, 45.665996],
    [124.128553, 45.665103],
    [124.121875, 45.669251],
    [124.137456, 45.679385],
    [124.137843, 45.687207],
    [124.125456, 45.695186],
    [124.112004, 45.696813],
    [124.099519, 45.703005],
    [124.098068, 45.722472],
    [124.089068, 45.727508],
    [124.082196, 45.736425],
    [124.054131, 45.751318],
    [124.033034, 45.750741],
    [124.019969, 45.747857],
    [124.014743, 45.749954],
    [124.001969, 45.770662],
    [124.00942, 45.781983],
    [124.034679, 45.784917],
    [124.049776, 45.794663],
    [124.062551, 45.796549],
    [124.06439, 45.80226],
    [124.042712, 45.815355],
    [124.034776, 45.8263],
    [124.036228, 45.83808],
    [124.047163, 45.840436],
    [124.06439, 45.839284],
    [124.071067, 45.845199],
    [124.068648, 45.868802],
    [124.058196, 45.889623],
    [124.03826, 45.894016],
    [124.001872, 45.904423],
    [123.992775, 45.910331],
    [123.971581, 45.93192],
    [123.967516, 45.94117],
    [123.965677, 45.96071],
    [123.970323, 45.971104],
    [123.977097, 45.975856],
    [124.012033, 45.982018],
    [124.020066, 45.989015],
    [124.01513, 45.998464],
    [123.992775, 46.007912],
    [123.99142, 46.01741],
    [124.005356, 46.021376],
    [124.027808, 46.016314],
    [124.038357, 46.018036],
    [124.039711, 46.031132],
    [124.031292, 46.048919],
    [124.009904, 46.057524],
    [124.005356, 46.069047],
    [124.016775, 46.076658],
    [124.018227, 46.08432],
    [124.012614, 46.09047],
    [123.995678, 46.098755],
    [124.000614, 46.110009],
    [124.016679, 46.118604],
    [124.015904, 46.124177],
    [123.995291, 46.137821],
    [123.993839, 46.147297],
    [124.008936, 46.154897],
    [124.010969, 46.161716],
    [124.002065, 46.166816],
    [123.985517, 46.163745],
    [123.97429, 46.165515],
    [123.971968, 46.170303],
    [123.975258, 46.183935],
    [123.961613, 46.194443],
    [123.957064, 46.206874],
    [123.965581, 46.213634],
    [123.97971, 46.218677],
    [123.982323, 46.226424],
    [123.956, 46.244667],
    [123.952225, 46.259269],
    [123.962774, 46.269451],
    [123.966452, 46.277086],
    [123.958322, 46.289965],
    [123.934806, 46.285863],
    [123.929386, 46.279683],
    [123.928225, 46.267685],
    [123.917579, 46.256827],
    [123.904224, 46.258801],
    [123.89745, 46.264568],
    [123.907902, 46.278488],
    [123.902676, 46.291003],
    [123.908289, 46.295209],
    [123.896288, 46.30362],
    [123.866675, 46.299518],
    [123.849836, 46.302374],
    [123.824964, 46.285188],
    [123.804931, 46.283785],
    [123.805028, 46.27667],
    [123.792447, 46.273398],
    [123.787221, 46.267581],
    [123.756736, 46.259944],
    [123.744252, 46.264048],
    [123.725574, 46.255684],
    [123.704187, 46.25797],
    [123.696832, 46.254385],
    [123.679605, 46.253657],
    [123.668186, 46.258489],
    [123.631217, 46.253138],
    [123.604991, 46.251787],
    [123.598894, 46.245135],
    [123.596668, 46.234065],
    [123.574216, 46.230218],
    [123.569473, 46.223616],
    [123.561925, 46.236404],
    [123.544602, 46.235053],
    [123.531827, 46.241757],
    [123.526408, 46.24924],
    [123.499891, 46.259528],
    [123.477923, 46.249604],
    [123.479374, 46.244252],
    [123.464761, 46.240146],
    [123.453148, 46.232558],
    [123.450728, 46.237028],
    [123.43147, 46.243836],
    [123.417631, 46.24051],
    [123.408824, 46.244096],
    [123.40476, 46.239626],
    [123.394308, 46.244667],
    [123.386662, 46.227827],
    [123.373791, 46.22294],
    [123.356178, 46.228399],
    [123.359371, 46.230322],
    [123.347371, 46.245863],
    [123.335951, 46.244304],
    [123.320661, 46.254592],
    [123.32008, 46.251423],
    [123.302176, 46.248305],
    [123.280305, 46.253917],
    [123.27624, 46.261347],
    [123.253594, 46.265347],
    [123.248175, 46.273294],
    [123.223303, 46.270541],
    [123.211884, 46.262438],
    [123.178592, 46.248097],
    [123.144624, 46.229491],
    [123.128462, 46.210462],
    [123.127688, 46.174674],
    [123.102913, 46.17202],
    [123.112397, 46.130062],
    [123.069912, 46.123552],
    [123.045815, 46.100058],
    [122.956974, 46.090418],
    [122.793131, 46.073217],
    [122.828551, 45.912266],
    [122.826712, 45.897991],
    [122.817712, 45.881254],
    [122.800292, 45.856714],
    [122.772808, 45.856557],
    [122.76642, 45.845356],
    [122.752388, 45.834834],
    [122.772033, 45.806398],
    [122.771356, 45.801474],
    [122.789937, 45.774803],
    [122.792453, 45.766259],
    [122.77484, 45.75352],
    [122.771162, 45.746231],
    [122.75113, 45.73611],
    [122.761001, 45.722262],
    [122.746968, 45.720688],
    [122.748613, 45.712608],
    [122.742033, 45.705157],
    [122.671482, 45.700644],
    [122.669063, 45.716596],
    [122.663644, 45.721213],
    [122.665289, 45.729973],
    [122.649611, 45.732281],
    [122.648837, 45.74513],
    [122.640611, 45.771134],
    [122.611578, 45.774908],
    [122.603448, 45.778105],
    [122.567157, 45.806817],
    [122.566867, 45.814464],
    [122.556125, 45.821587],
    [122.544415, 45.815878],
    [122.533382, 45.80556],
    [122.530769, 45.798278],
    [122.518285, 45.784708],
    [122.504349, 45.787747],
    [122.501736, 45.806136],
    [122.503285, 45.821115],
    [122.495833, 45.858232],
    [122.475413, 45.877539],
    [122.445993, 45.916919],
    [122.374959, 45.920682],
    [122.362087, 45.917441],
    [122.360345, 45.903325],
    [122.362474, 45.887008],
    [122.368958, 45.862104],
    [122.37312, 45.856139],
    [122.355216, 45.855196],
    [122.337409, 45.859907],
    [122.335958, 45.849858],
    [122.321248, 45.84431],
    [122.32086, 45.830436],
    [122.302957, 45.829913],
    [122.311957, 45.825985],
    [122.299086, 45.818287],
    [122.301602, 45.812998],
    [122.281859, 45.809331],
    [122.285634, 45.802207],
    [122.274601, 45.805246],
    [122.262698, 45.794978],
    [122.255246, 45.796759],
    [122.245471, 45.810484],
    [122.243729, 45.825514],
    [122.236471, 45.831483],
    [122.219342, 45.830122],
    [122.216245, 45.845042],
    [122.204825, 45.850277],
    [122.200954, 45.856871],
    [122.190309, 45.854621],
    [122.185663, 45.859279],
    [122.155663, 45.866605],
    [122.147146, 45.864825],
    [122.138146, 45.873145],
    [122.115984, 45.873982],
    [122.101661, 45.881306],
    [122.091596, 45.881986],
    [122.086951, 45.894644],
    [122.082403, 45.896474],
    [122.085306, 45.909913],
    [122.081338, 45.920944],
    [122.071177, 45.930614],
    [122.055208, 45.932965],
    [122.043402, 45.94326],
    [122.039724, 45.959247],
    [122.016885, 45.961546],
    [122.00653, 45.966717],
    [122.004594, 45.984629],
    [121.994433, 45.982018],
    [121.987949, 45.971679],
    [121.961335, 45.981078],
    [121.962109, 45.986352],
    [121.944399, 45.989328],
    [121.928044, 45.988336],
    [121.922915, 45.997159],
    [121.923592, 46.004884],
    [121.894753, 46.008225],
    [121.893785, 46.002275],
    [121.864074, 46.002431],
    [121.858848, 46.01026],
    [121.848009, 46.014905],
    [121.842977, 46.024454],
    [121.823912, 46.023097],
    [121.812879, 46.025863],
    [121.814621, 46.02148],
    [121.796815, 46.010626],
    [121.786556, 46.011043],
    [121.785395, 46.003266],
    [121.764588, 46.000656],
    [121.759072, 45.993609],
    [121.767685, 45.984681],
    [121.783846, 45.986561],
    [121.809299, 45.961546],
    [121.806395, 45.957001],
    [121.811525, 45.94744],
    [121.803782, 45.942058],
    [121.811041, 45.937564],
    [121.807557, 45.9326],
    [121.818105, 45.927791],
    [121.821396, 45.918382],
    [121.805718, 45.900605],
    [121.808718, 45.887792],
    [121.817138, 45.875604],
    [121.804073, 45.873145],
    [121.785976, 45.860848],
    [121.778911, 45.848392],
    [121.769911, 45.843524],
    [121.764201, 45.831431],
    [121.772911, 45.826666],
    [121.767685, 45.819283],
    [121.75762, 45.818968],
    [121.751039, 45.804984],
    [121.754233, 45.794873],
    [121.741652, 45.791886],
    [121.740394, 45.786751],
    [121.72249, 45.785337],
    [121.688231, 45.763219],
    [121.675457, 45.769457],
    [121.656972, 45.770191],
    [121.644391, 45.752471],
    [121.648843, 45.747333],
    [121.669263, 45.739152],
    [121.664521, 45.729711],
    [121.670521, 45.723364],
    [121.688909, 45.712661],
    [121.71407, 45.701798],
    [121.733039, 45.699437],
    [121.756846, 45.690724],
    [121.782105, 45.693768],
    [121.811331, 45.686945],
    [121.808525, 45.693926],
    [121.812299, 45.70479],
    [121.852558, 45.717855],
    [121.867074, 45.719744],
    [121.894172, 45.713605],
    [121.899204, 45.718012],
    [121.933947, 45.710614],
    [121.944786, 45.71413],
    [121.970142, 45.692771],
    [121.994045, 45.637631],
    [122.000046, 45.633795],
    [122.003336, 45.623286],
    [121.995497, 45.605257],
    [121.996078, 45.598948],
    [121.979819, 45.595636],
    [121.966464, 45.596161],
    [121.972561, 45.568971],
    [121.993368, 45.552872],
    [121.993852, 45.539821],
    [122.003336, 45.524345],
    [122.002465, 45.507864],
    [122.023466, 45.49022],
    [122.033434, 45.487428],
    [122.063338, 45.472939],
    [122.08637, 45.46672],
    [122.12121, 45.453438],
    [122.157211, 45.446269],
    [122.164566, 45.443264],
    [122.168727, 45.430135],
    [122.179857, 45.409564],
    [122.146856, 45.374525],
    [122.143662, 45.35441],
    [122.144049, 45.322454],
    [122.147243, 45.295607],
    [122.187696, 45.28625],
    [122.238987, 45.27631],
    [122.238503, 45.263248],
    [122.241794, 45.247749],
    [122.230084, 45.206839],
    [122.216438, 45.193919],
    [122.192631, 45.180679],
    [122.143275, 45.18301],
    [122.126823, 45.159064],
    [122.109887, 45.142053],
    [122.118694, 45.0999],
    [122.119468, 45.068597],
    [122.108242, 45.049913],
    [122.098951, 45.021556],
    [122.074564, 45.006629],
    [122.086758, 44.952944],
    [122.079112, 44.914217],
    [122.063725, 44.911929],
    [122.049595, 44.912833],
    [122.04495, 44.899475],
    [122.05395, 44.896973],
    [122.056273, 44.882812],
    [122.070499, 44.882067],
    [122.074854, 44.860605],
    [122.084435, 44.851603],
    [122.082596, 44.839562],
    [122.089371, 44.833274],
    [122.098177, 44.818778],
    [122.100113, 44.809076],
    [122.092371, 44.799532],
    [122.099919, 44.782361],
    [122.115016, 44.7766],
    [122.157405, 44.777347],
    [122.169115, 44.770252],
    [122.153824, 44.763797],
    [122.142695, 44.753712],
    [122.127404, 44.757767],
    [122.110661, 44.767798],
    [122.098855, 44.7448],
    [122.102726, 44.73626],
    [122.116274, 44.739836],
    [122.152469, 44.743839],
    [122.161372, 44.728252],
    [122.148888, 44.715919],
    [122.131081, 44.710365],
    [122.117436, 44.702141],
    [122.115597, 44.688626],
    [122.103016, 44.673986],
    [122.113468, 44.615444],
    [122.131952, 44.603675],
    [122.13834, 44.588158],
    [122.131662, 44.577507],
    [122.163405, 44.567979],
    [122.196018, 44.559841],
    [122.202793, 44.554647],
    [122.208116, 44.54067],
    [122.224084, 44.526207],
    [122.22631, 44.518439],
    [122.222632, 44.497753],
    [122.214696, 44.493733],
    [122.228148, 44.48017],
    [122.244891, 44.47572],
    [122.258827, 44.476149],
    [122.272666, 44.473468],
    [122.286118, 44.477758],
    [122.29444, 44.410913],
    [122.291634, 44.310287],
    [122.274214, 44.273819],
    [122.265891, 44.267255],
    [122.271408, 44.255684],
    [122.311957, 44.234637],
    [122.319409, 44.232914],
    [122.367023, 44.235929],
    [122.482671, 44.236898],
    [122.512865, 44.250248],
    [122.559318, 44.260205],
    [122.597351, 44.272528],
    [122.642837, 44.283611],
    [122.675547, 44.285762],
    [122.690064, 44.296843],
    [122.691709, 44.303296],
    [122.703128, 44.318943],
    [122.726645, 44.342003],
    [122.760614, 44.369727],
    [122.777066, 44.375958],
    [122.856132, 44.398297],
    [122.920199, 44.4312],
    [122.940909, 44.445418],
    [122.95078, 44.449173],
    [123.024911, 44.492983],
    [123.040202, 44.499093],
    [123.067106, 44.506168],
    [123.083558, 44.506329],
    [123.111526, 44.509705],
    [123.125462, 44.509276],
    [123.139398, 44.522939],
    [123.142301, 44.545008],
    [123.148592, 44.554005],
    [123.178979, 44.569799],
    [123.201335, 44.576972],
    [123.213238, 44.574349],
    [123.2204, 44.590566],
    [123.244981, 44.596934],
    [123.247691, 44.606404],
    [123.253594, 44.605708],
    [123.27324, 44.620205],
    [123.285821, 44.624055],
    [123.289789, 44.635874],
    [123.295015, 44.638066],
    [123.317951, 44.639403],
    [123.3315, 44.649561],
    [123.348145, 44.652822],
    [123.370307, 44.662817],
    [123.383856, 44.663458],
    [123.418405, 44.671528],
    [123.455954, 44.696105],
    [123.502117, 44.707695],
    [123.523795, 44.707001],
    [123.525537, 44.727291],
    [123.514795, 44.734338],
    [123.4786, 44.77836],
    [123.467955, 44.793453],
    [123.449857, 44.812221],
    [123.429825, 44.849312],
    [123.419663, 44.855758],
    [123.451987, 44.892182],
    [123.497955, 44.930977],
    [123.489729, 44.952838],
    [123.492536, 44.959538],
    [123.519924, 44.96629],
    [123.530666, 44.951827],
    [123.540924, 44.952997],
    [123.556796, 44.960548],
    [123.57228, 44.963153],
    [123.587377, 44.971553],
    [123.611571, 44.995046],
    [123.632282, 45.009445],
    [123.689573, 45.033984],
    [123.720348, 45.048745],
    [123.746865, 45.064351],
    [123.82748, 45.130762],
    [123.83619, 45.142159],
    [123.851965, 45.151275],
    [123.879159, 45.17231],
    [123.882449, 45.178613],
    [123.919321, 45.209539],
    [123.934515, 45.213774],
    [123.942161, 45.219544],
    [123.976323, 45.225895],
    [123.988033, 45.225895],
    [124.025001, 45.230817],
    [124.053744, 45.231611],
    [124.069422, 45.236109],
    [124.079874, 45.249283],
    [124.086842, 45.263089],
    [124.065357, 45.285722],
    [124.07368, 45.314158],
    [124.087035, 45.32779],
    [124.100681, 45.332544],
    [124.115294, 45.332756],
    [124.12623, 45.336136],
    [124.202393, 45.374367],
    [124.224652, 45.382812],
    [124.26162, 45.402706],
    [124.285621, 45.420589],
    [124.334784, 45.436093],
    [124.347171, 45.445109],
    [124.373785, 45.458551]
]

export function mapSelect(id) {

    let mapData; //地图数据

    switch (id) {
        case 1: //河北馆陶
            mapData = hebeiguantao_mapData;
            break
        case 2: //安徽含山
            mapData = anhuihanshan_mapData;
            break
        case 3: //河南开封
            mapData = shuidaoxiang_mapData;
            break
        case 4: //吉林白城
            mapData = jilinbaicheng_mapData;
            break
        default:
            throw new Error('暂无匹配的数据');
    }

    return mapData;
}
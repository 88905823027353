// 引入公共API接口
import { getsysUserDeviceAPI, getRealTimeDataAPI, loginAPI } from "@/api/user.js";

// 引入env.js文件
import Projectmodel from "@/utils/env"

// 引入vuex
import store from "@/store";

// 引入mock假数据
import { falseDataSelection } from "./mock"

/**
 *  工具函数
 * 存放公共函数及 接口请求API接口
 *
 */

// 获取用户设备---获取用户地址及区域ID
export const getUserDeviceAPI = async(deviceType) => {
    const {
        data
    } = await getsysUserDeviceAPI({
        deviceType
    });

    const userDevice = {
        deviceAddrList: [], // 存储设备地址
        groupIdList: [], // 存储区域id
    };

    data.forEach((item) => {
        userDevice.deviceAddrList.push(item.deviceAddr);
        userDevice.groupIdList.push(item.groupId);
    });

    return userDevice;
};

/**
 * 获取设备实时记录
 * @param {string} value met、wormFlagship、soil、spore
 * @returns 设备列表数据
 * 切勿修改,所有数据都基于此函数
 */
export const getDeviceRealTimeDataAPI = async(value) => {
    /**
     * 以前在API接口仅返回设备在线实时设备数据
     * 
     * 2024-06-20日 更改
     * 
     * 1.先获取用户设备(在线、离线设备)
     * 2.在获取实时设备数据(在线)
     * 3.对比
     *   3.1 设备全部在线
     *   3.2 设备全部离线
     *   3.3 设备部分在线、部分离线
     * 4.将数据进行整合,并且返回
     * 5.由于设备全部离线无法得知设备需要展示的数据,增加了mock.js文件,存储假数据
     * 6.不要问为什么后端不写接口,因为这个是建大仁科的后台接口,需要联系建大仁科,人家不鸟咱
     * 
     * 经过测试: 安徽含山、河北馆陶 均可使用
     * 
     */

    // 获取用户设备(在线、离线均可获取)
    const userDeviceList = await getsysUserDeviceAPI({
        deviceType: value
    })


    // 设备地址
    const deviceAddrs = []

    // 如果用户设备没有的话,返回[]
    if (userDeviceList.data.length === 0) return []

    // 获取设备地址
    userDeviceList.data.forEach(item => deviceAddrs.push(item.deviceAddr))

    // 获取设备实时记录
    const realRecordingList = await getRealTimeDataAPI({ deviceAddrs: deviceAddrs.join(',') })

    // console.log(realRecordingList); //实时记录
    // console.log(userDeviceList); //用户设备

    // 最终整合数据存储
    let deviceList = []

    // 判断设备是否在线
    deviceList = realRecordingList.data.filter(item1 => userDeviceList.data.some(item2 => item2.deviceAddr === item1.deviceAddr))

    // 全部在线,返回数据;否则执行后续代码
    if (deviceList.length === userDeviceList.data.length) {
        console.log('---设备全部在线---')
        return deviceList
    }

    // 判断设备是否离线
    let notOnlineDeviceList = userDeviceList.data.filter(item1 => !realRecordingList.data.some(item2 => item2.deviceAddr === item1.deviceAddr))


    // 设备离线数量和用户设备数量相同时,表示全部离线,需要调用mock.js假数据
    if (notOnlineDeviceList.length === userDeviceList.data.length) {
        console.log('---设备全部离线---')

        // 循环离线设备(这个数据里面有设备地址、名称、经纬度、设备类型)
        notOnlineDeviceList = notOnlineDeviceList.map(item => {
            return {
                ...item,
                data: falseDataSelection(value).data, // mock.js假数据
                status: 'offline', //离线 
                lat: item.devicelat, //纬度
                lng: item.devicelng, //经度
            }
        })

        // 返回
        return notOnlineDeviceList
    }

    // 表示设备有的在线,有的离线,需要合并在线、离线数据
    // 深拷贝此数据

    let copyDevice = deepCopyAPI(deviceList[0].data)

    console.log(`---在线设备:${realRecordingList.data.length}个、离线设备:${notOnlineDeviceList.length}个---`)

    // 判断拷贝的数据是否为对象,是否为数组
    if (Object.prototype.toString.call(copyDevice) === '[object Array]') {
        // 数组,使用mock.js假数据
        copyDevice = falseDataSelection(value).data
    } else if (Object.prototype.toString.call(copyDevice) === '[object Object]') {
        // 对象,清空对象的属性值
        for (const item in copyDevice) {
            copyDevice[item] = ''
        }
    }

    // 将在线的设备与离线的设备进行合并 
    deviceList = [...deviceList, ...notOnlineDeviceList.map(item => {
        return {
            data: copyDevice, //设备展示的数据
            deviceAddr: item.deviceAddr, //设备地址码
            deviceName: item.deviceName, //设备名称
            deviceType: item.deviceType, //设备类型
            lat: item.devicelat, //经度
            lng: item.devicelat, //纬度
            status: 'offline', //状态(统一为offline,离线状态)
            timeStamp: item.timeStamp, //时间戳
        }
    })]
    return deviceList
}


/**
 * LOGIN_API
 */
export const login_API = async() => {
    const login_Form = {
        loginName: Projectmodel.loginName, //账号--->安徽含山
        loginPwd: Projectmodel.loginPwd, //密码--->安徽含山
    }
    const {
        data
    } = await loginAPI(login_Form)

    // 存储TOKEN
    store.commit("token/getToken", data.token);
    // 存储登录时间
    store.commit("token/timer", new Date().toLocaleString());
}


/**
 * 筛选设备类型
 * ps:针对气象设备
 * 2开头：杀虫灯
 * 4开头：气象设备
 */
export const meteorologicalScreeningAPI = (arr, value) => {
    /**
     * 以deviceAddr为key进行筛选
     */
    return arr.filter(item => item.deviceAddr.toString().startsWith(value))
}


// 时间函数
export const timeAPI = (id = 0) => {

    // 获取时间
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth() + 1; // 月份从 0 开始计数，所以需要加 1
    var day = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();

    if (id === 1) {
        // 当 id 为 1 时，提前一个月
        now.setMonth(now.getMonth() - 1);
        year = now.getFullYear();
        month = now.getMonth() + 1; // 更新月份
        day = now.getDate(); // 更新日期
    }

    // 年月日
    let specificDate = `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;

    // 时分秒
    let HourDate = `${hour < 10 ? "0" + hour : hour}:${
    minute < 10 ? "0" + minute : minute
  }:${second < 10 ? "0" + second : second}`;

    // return 出去
    return {
        HourDate,
        specificDate,
    };
};

// 数据深拷贝
export const deepCopyAPI = (obj) => {
    if (typeof obj !== "object" || obj === null) {
        return obj;
    }

    let copy = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            copy[key] = deepCopyAPI(obj[key]);
        }
    }

    return copy;
};

// 拖动元素
export const dragElementAPI = (element) => {
    let startX, startY, elementLeft, elementTop;

    // 鼠标移入 更换鼠标样式
    element.addEventListener("mouseover", (e) => {
        if (e.target.dataset.dept == "drag") {
            element.style.cursor = "all-scroll";
        } else {
            element.style.cursor = "auto";
        }
    });

    // 鼠标按下 拖动元素
    element.addEventListener("mousedown", startDrag);

    function startDrag(e) {
        if (e.target.dataset.dept == "drag") {
            startX = e.clientX;
            startY = e.clientY;
            elementLeft = element.offsetLeft;
            elementTop = element.offsetTop;
            document.addEventListener("mousemove", doDrag);
            document.addEventListener("mouseup", stopDrag);
        }
    }

    function doDrag(e) {
        var dx = e.clientX - startX;
        var dy = e.clientY - startY;
        element.style.left = elementLeft + dx + "px";
        element.style.top = elementTop + dy + "px";
    }

    function stopDrag() {
        document.removeEventListener("mousemove", doDrag);
        document.removeEventListener("mouseup", stopDrag);
    }
};

// 判断对象里的属性是否为空
export const isEmptyCheckAPI = (value) => {
    return Object.values(value).every((item) => item !== null && item !== "");
};

// 消息提示
export const messagePromptAPI = ({
    code,
    message,
    data
}) => {
    if (code === 1000) {
        // 传递数据
        store.commit("message/getMessageData", {
            state: true,
            data: message
        });
    } else if (code === 1001 || code === 1002) {
        // 传递数据
        store.commit("message/getMessageData", {
            state: false,
            data: `${message} - ${data}`,
        });
    } else {
        // 传递数据
        store.commit("message/getMessageData", {
            data: message
        });
    }

    // 打开消息提示
    store.commit("message/openMessage", true);
};

// 打开弹窗
export const openPopupAPI = (result, value) => {
    // 通过vuex传递数据
    store.commit("weatherData/getWeatherData", result);
    // 判断打开的是哪个组件
    store.commit("popupState/option", value);
    // 打开弹窗
    store.commit("popupState/open", true);
};

// 时间选择过滤 T
export const timeFilterAPI = (value) => {
    return value.replace("T", " ");
};

// 时间选择格式化 时间戳
export const timeFormattingAPI = (value) => {
    return String(Date.parse(value));
};

// 设备类型
export const deviceTypesAPI = (value) => {
    const deviceTypes = {
        1: "使能",
        2: "禁用",
        3: "使能",
        4: "浮点型设备",
        5: "开关量型设备",
        6: "32位有符号整形",
        7: "32位无符号整形",
        8: "遥调设备",
    };

    return deviceTypes[value] || "未知设备类型";
};

// 品牌设备类型(气象设备、虫情设备.....)
export const brandDeviceTypesAPI = (value) => {
    const brandDeviceTypes = {
        worm: "worm",
        spore: "spore",
        met: 'met',
        soil: 'soil',
        irrigation: 'irrigation',
        camera: 'camera',
        wormFlagship: 'wormFlagship',
    }

    return brandDeviceTypes[value] || "未知设备类型";
}


// 判断类型种类
export const countDeviceTypesAPI = (arr) => {
    const deviceTypes = {};

    for (let i = 0; i < arr.length; i++) {
        const device = arr[i];
        const deviceType = device.deviceType;

        if (deviceType in deviceTypes) {
            deviceTypes[deviceType]++;
        } else {
            deviceTypes[deviceType] = 1;
        }
    }

    const result = [];
    for (const type in deviceTypes) {

        result.push({
            name: type,
            value: deviceTypes[type]
        });
    }

    const array = [{
        name: "wormFlagship",
        chineseName: "虫情设备"
    }, {
        name: "spore",
        chineseName: "孢子设备"
    }, {
        name: "met",
        chineseName: "气象设备"
    }, {
        name: "soil",
        chineseName: "墒情设备"
    }]

    result.forEach(item1 => {
        array.forEach(item2 => {
            if (item1.name === item2.name) {
                item1.name = item2.chineseName
            }
        })
    })

    return result;
}


// 筛选设备类型 
export const filterDeviceTypeAPI = (arr, value) => {

    const newList = []

    arr.forEach(item => {
        if (item.deviceType == value) {
            newList.push(item)
        }
    })


    return newList
}


/**
 * 防抖
 * @param {Function} func    函数
 * @param {number} duration  时间
 * @returns {Function} 
 */
export function debounce(func, duration) {
    let timerID = null;
    return (...args) => {
        timerID && clearTimeout(timerID)
        timerID = setTimeout(() => {
            func(...args)
        }, duration)
    }
}
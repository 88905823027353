// 引入地图数据
import { mapSelect } from "@/utils/map"

/**
 * 2024-06-18
 * 
 * @file 该文件配置了不同项目的配置选项(必须全部填写!!!),根据 project_name参数进行配置
 * 
 * @summary 配置不同项目的配置文件
 * 
 * @description 
 *  此文件提供了不同项目的配置选项,包含登录账号、密码、接口字段、后台系统URL、项目版本、标题、地图数据等
 * 
 * @author kankan
 * 
 * @param {number} project_name - 用于确定配置设置的项目名称
 * @returns {Object} Projectmodel - 返回项目配置对象
 * 
 * @attention 
 *  1.在使用本文件时,确保正确配置了项目的所有参数!!!
 *  2.测试时,需要将缓存清空.
 *  3.项目打包时.确定使用哪个项目,设置 project_name 字段即可.
 */

// 项目模块配置
const Projectmodel = {
    loginName: "", //账号
    loginPwd: "", //密码
    interfaceFields: "", //api接口字段
    backendSystem: "", //后台系统
    projectVersion: "", //项目版本 (表示使用的是 安徽含山版本? 还是河北馆陶版本?)
    henghui_BaseURL: "", //恒辉接口基地址 (正式地址)
    renke_BaseURL: "http://api.farm.0531yun.cn", //建大仁科接口基地址(正式地址)
    chineseTitle: "", //项目中文名
    englishTitle: "", //项目英文名
    mapState: [], //地图地址数据--->配合utils/map.js文件使用(所有地图数据都放入此文件中)
    geographicLocation: [], //地图地理位置
}

/**
 *  project_name : 1 ---> 河北馆陶项目
 *  project_name : 2 ---> 安徽含山项目
 *  project_name : 3 ---> 开封水稻乡项目
 *  project_name : 4 ---> 吉林白城项目
 * 
 *  注意: 在开发环境中添加s https://os.ouyuanrui.top;生产环境中删除s http://os.ouyuanrui.top
 */
const project_name = 3 //默认使用 河北馆陶项目数据

if (project_name === 1) {
    // 河北馆陶
    Projectmodel.loginName = 'hebeiguantao';
    Projectmodel.loginPwd = '123456';
    Projectmodel.interfaceFields = 'guantao'
    Projectmodel.backendSystem = 'https://os.henghuitec.top/vgod.php/direct/login?key=Z3VhbnRhb0AxMjM0NTY%3D&url=%2Fvgod.php%2Faio%2Faio_bind%3Fref%3Daddtabs'
    Projectmodel.projectVersion = 1
    Projectmodel.henghui_BaseURL = 'https://os.henghuitec.top'
    Projectmodel.chineseTitle = "馆陶县"
    Projectmodel.englishTitle = "Guantao County High standard Farmland Digital Management Platform"
    Projectmodel.mapState = mapSelect(1)
    Projectmodel.geographicLocation = [115.28854, 36.55396]
} else if (project_name === 2) {
    // 安徽含山
    Projectmodel.loginName = 'anhuihanshan';
    Projectmodel.loginPwd = '123456';
    Projectmodel.interfaceFields = 'anhuihanshan'
    Projectmodel.backendSystem = 'http://os.ouyuanrui.top/vgod.php/direct/login/key/YW5odWloYW5zaGFuQDEyMzQ1Ng%3D%3D'
    Projectmodel.projectVersion = 2
    Projectmodel.henghui_BaseURL = 'http://os.ouyuanrui.top'
    Projectmodel.chineseTitle = "含山县"
    Projectmodel.englishTitle = "High standard digital management platform for farmland in Hanshan County"
    Projectmodel.mapState = mapSelect(2)
    Projectmodel.geographicLocation = [118.101, 31.734699]
} else if (project_name === 3) {
    // 开封水稻乡
    Projectmodel.loginName = 'keifengshuidaoxiang';
    Projectmodel.loginPwd = '123456';
    Projectmodel.interfaceFields = 'shuidaoxiang';
    Projectmodel.backendSystem = ''
    Projectmodel.projectVersion = 3
    Projectmodel.henghui_BaseURL = 'http://os.ouyuanrui.top'
    Projectmodel.chineseTitle = "水稻乡"
    Projectmodel.englishTitle = "Digital Management Platform for High Standard Farmland in Rice Township"
    Projectmodel.mapState = mapSelect(3)
    Projectmodel.geographicLocation = [114.303190, 34.871989]
} else if (project_name === 4) {
    // 吉林白城
    Projectmodel.loginName = 'jilinbaicheng';
    Projectmodel.loginPwd = '123456';
    Projectmodel.interfaceFields = 'jilinbaicheng'
    Projectmodel.backendSystem = ''
    Projectmodel.projectVersion = 4
    Projectmodel.henghui_BaseURL = 'https://os.ouyuanrui.top'
    Projectmodel.chineseTitle = "白城"
    Projectmodel.englishTitle = "Baicheng High Standard Farmland Digital Management Platform"
    Projectmodel.mapState = mapSelect(4)
    Projectmodel.geographicLocation = [122.838997, 45.619699]
}

// 导出数据
export default Projectmodel